
import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert';
import { get } from 'scriptjs';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from '../../utility.service';
import { ApiDataService } from '../../api-data.service';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.css']
})
export class PurchaseComponent implements OnInit, AfterViewInit {
  section = this.utility.apiData.invoice;
  isLoadingData = true;
  etr = JSON.parse(sessionStorage.getItem("etrUser"));
  object = {};

  constructor(private router: Router, private route: ActivatedRoute, private utility: UtilityService, private dataService: ApiDataService, private http: HttpClient, private changeDetectorRef: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.hasData();
  }

  saveDetails(details, etrID, id, service, purchaseURL) {
    let json = {
      "paypal": details,
      "etrid": etrID,
      "id": id
    }
    service.putData(purchaseURL, JSON.stringify(json))
      .subscribe(Response => {
        console.log(Response);
        swal("Payment Made successfully");
        document.location.href = `/owner/invoices`;
      }, error => {
        swal("Saving Data Failed");
        document.location.href = `/owner/invoices`;
      })
  }

  renderButton(object) {
    let savedetails = this.saveDetails
    let currency = "USD"
    let service = this.dataService;
    let purchaseURL = this.section.ApiUrl;
    let desp = "Enteprise payment for period of " + object.fromDate + " to " + object.toDate;

    get('https://www.paypal.com/sdk/js?client-id=AflmBnROardkIoE9SUvSO0apG8hDCfKS9HnAoxMZ2gQwedBpp5ntn0ri0dvfPNRPFNHkiOv1KyNakJz3&currency=' + currency, () => {
      //@ts-ignore
      paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'blue',
          layout: 'vertical',
          label: 'pay',
        },
        createOrder: function (data, actions) {
          //refetch package details and couopn code and then send that -- (future)
          return actions.order.create({
            purchase_units: [{
              "description": desp,
              "amount": {
                "currency_code": currency,
                "value": object.totalPrice.toFixed(2),
                "breakdown": {
                  "item_total": {
                    "currency_code": currency,
                    "value": object.price.toFixed(2)
                  },
                  "shipping": {
                    "currency_code": currency,
                    "value": 0
                  },
                  "tax_total": {
                    "currency_code": currency,
                    "value": object.taxPrice.toFixed(2)
                  }
                }
              }
            }]
          });
        },
        onApprove: function (data, actions) {
          swal("Processing Payment!", "please wait...", "info", {
            buttons: [false, false],
            closeOnClickOutside: false,
          });
          return actions.order.capture().then(function (details) {
            savedetails(details, object.etrID, object.EtrInvoiceId, service, purchaseURL);
          });
        },
        onError: function (err) {
          console.log(err);
          swal("unable to process payment,please try again");
        }
      }).render('#paypal-button-container');
    });
  }

  hasData() {
    if (!this.etr || !this.etr.etrID) {
      swal("Please Login to make Payment");
      this.router.navigate(['/login']);
    }
    this.route.paramMap.subscribe(params => {
      if (!params.get('id') || params.get('id') == "") {
        swal("invalid Invoice");
        this.router.navigate(['/invoices']);
        return;
      }
      this.loadData(params.get('id'));
    });
  }

  loadData(id) {
    //Get Form Data via API
    this.dataService.getallData(this.section.ApiUrl + `?etrid=${this.etr.etrID}&id=${id}`).subscribe(
      (Response) => {
        if (Response['paymentDate']) {
          swal("Invoice Paid already");
          document.location.href = `/owner/invoices`;
        } else {
          this.object = Response;
          this.renderButton(Response);
          this.isLoadingData = false;
        }
      },
      (error) => {
        swal("No data exists");
        this.router.navigate(['/invoices']);
      });
  }

  ngOnInit() {
  }
}
