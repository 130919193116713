import { AddEditData, UtilityService } from "./../../utility.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { v4 as uuidv4 } from "uuid";
import swal from "sweetalert";
import { ApiDataService } from "src/app/api-data.service";
import { Location } from '@angular/common';
@Component({
  selector: 'app-addinvoice',
  templateUrl: './addinvoice.component.html',
  styleUrls: ['./addinvoice.component.css']
})
export class AddinvoiceComponent implements OnInit, AddEditData {
  // step 1 : Assign section variable correctly
  // Step 2 : If EditMode validate and Bind Html input to this object using template driven forms (2-way Binding ([ngModel]))
  // Step 3 : If any Binary Data is uploaded add it to Binary Array
  // Step 4 : on Submit validate schema to be sent-->if valid upload Binary Array to S3 ,upon succes upload Form Data
  // Step 6 : Reset State of form after submit
  // CamelCase for naming anything
  // select this appropriately
  section = this.utility.apiData.invoice;
  object: any;
  isEditMode = false;
  isUploadingData = false;
  isLoadingData = false;
  binaryFiles = [];
  constructor(
    private route: ActivatedRoute,
    private utility: UtilityService,
    private dataService: ApiDataService,
    private location: Location
  ) { }
  @ViewChild("mainForm", { static: false }) mainForm: NgForm;
  etr = JSON.parse(sessionStorage.getItem("etrUser"));
  ngOnInit() {
    this.resetForm();
    this.hasData();
  }
  resetForm() {
    this.isUploadingData = false;
    this.isLoadingData = false;
    this.isEditMode = false;
    // change
    this.object = {}
  }
  loadData(id) {
    //Get Form Data via API
    this.dataService.getallData(this.section.ApiUrl + `?etrid=${this.etr.etrID}&id=${id}`).subscribe(
      (Response) => {
        this.object = Response;
        this.isLoadingData = false;
      },
      (error) => {
        // swal("No data exists");
        // this.location.back();
      });
  }
  hasData() {
    this.route.paramMap.subscribe((params) => {
      if (params.get("id") && params.get("id") != "") {
        this.isLoadingData = true;
        this.isEditMode = true;
        this.loadData(params.get("id"));
      } else {
        swal('No Data Exists');
        this.location.back();
      }
    });
  }
  loadBinaryFile(event) {
  }
  uploadBinaryData() {
  }
  uploadFormData() {
  }
  onSubmit() {
  }
}
