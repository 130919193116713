import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { HttpClientModule } from '@angular/common/http';
import { CustomerdetailComponent } from './customer/customerdetail/customerdetail.component';
import { CustomerListComponent } from './customer/customer-list/customer-list.component';
import { EditAccountComponent } from './account/edit-account/edit-account.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { VendorTransactionDetailsComponent } from './owner-finance/transaction-details/transaction-details.component';
import { VendorTransactionListComponent } from './owner-finance/transaction-list/transaction-list.component';
import { AccountloginComponentComponent } from './accountlogin-component/accountlogin-component.component';
import { AddlocationComponent } from './location/addlocation/addlocation.component';
import { ListlocationComponent } from './location/listlocation/listlocation.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { ValidatepasswordComponent } from './validatepassword/validatepassword.component';
import { AddinvoiceComponent } from './invoices/addinvoice/addinvoice.component';
import { ListinvoicesComponent } from './invoices/listinvoices/listinvoices.component';
import { PurchaseComponent } from './invoices/purchase/purchase.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    CustomerdetailComponent,
    CustomerListComponent,
    EditAccountComponent,
    ForgetpasswordComponent,
    VendorTransactionDetailsComponent,
    VendorTransactionListComponent,
    AccountloginComponentComponent,
    AddlocationComponent,
    ListlocationComponent,
    ResetpasswordComponent,
    ValidatepasswordComponent,
    AddinvoiceComponent,
    ListinvoicesComponent,
    PurchaseComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
