import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert';
import { ApiDataService } from '../api-data.service';
import { UtilityService } from '../utility.service';

@Component({
  selector: 'app-validatepassword',
  templateUrl: './validatepassword.component.html',
  styleUrls: ['./validatepassword.component.css']
})
export class ValidatepasswordComponent implements OnInit {

  sending: boolean;
  id: string;
  random: string;

  constructor(private router: Router, private route: ActivatedRoute, private Service: ApiDataService, private utility: UtilityService,) { }

  ngOnInit() {
    this.sending = false;
    this.route.paramMap.subscribe(params => {
      if (params.get('id') && params.get('id') != "" && params.get('random') && params.get('random') != "") {
        this.id = params.get('id');
        this.random = params.get('random');
      } else {
        swal("Validation failed,please contact support@dentallive.com");
        this.sending = false;
        this.router.navigate(['/login']);
      }
    });
  };

  onSubmit(form: NgForm) {
    if (form.invalid) {
      form.form.markAllAsTouched();
      return;
    }
    let json: JSON = form.value;
    if (json['cpassword'] != json['npassword']) {
      swal('Password did not match');
      return;
    }
    this.sending = true;
    json['id'] = this.id
    json['verificationNo'] = this.random;
    json['password'] = json['cpassword'];
    json['isValidate'] = true;
    console.log(json);

    this.Service.postData(this.utility.apiData.ownerAccounts.ApiUrl, json)
      .subscribe(Response => {
        swal("Password created successfully")
        this.sending = false;
        this.router.navigate(['/login']);
      }, error => {
        console.log(error);
        if (error.status == 401) {
          swal("Validation failed,please contact support@dentallive.com");
          this.sending = false;
        }
        error.status == 404 ? swal("User not found") : swal("Error saving data,please try again");;
        this.sending = false;
      })
  }

}
