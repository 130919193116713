import { MainComponent } from './main/main.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerListComponent } from './customer/customer-list/customer-list.component';
import { CustomerdetailComponent } from './customer/customerdetail/customerdetail.component';
import { EditAccountComponent } from './account/edit-account/edit-account.component';
import { AccountloginComponentComponent } from './accountlogin-component/accountlogin-component.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { AuthGuardServiceService } from './auth-guard-service.service';
import { VendorTransactionListComponent } from './owner-finance/transaction-list/transaction-list.component';
import { VendorTransactionDetailsComponent } from './owner-finance/transaction-details/transaction-details.component';
import { ListlocationComponent } from './location/listlocation/listlocation.component';
import { AddlocationComponent } from './location/addlocation/addlocation.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { ValidatepasswordComponent } from './validatepassword/validatepassword.component';
import { ListinvoicesComponent } from './invoices/listinvoices/listinvoices.component';
import { AddinvoiceComponent } from './invoices/addinvoice/addinvoice.component';
import { PurchaseComponent } from './invoices/purchase/purchase.component';


const routes: Routes = [
  {
    path: 'owner',
    canActivate: [AuthGuardServiceService],
    component: MainComponent,
    children: [
      // customers
      { path: 'users', component: CustomerListComponent },
      { path: 'users/user/:id', component: CustomerdetailComponent },

      // customers
      { path: 'users/:id', component: CustomerListComponent },
      { path: 'users/user/:id', component: CustomerdetailComponent },

      // locations
      { path: 'locations', component: ListlocationComponent },
      { path: 'locations/location', component: AddlocationComponent },
      { path: 'locations/location/:id', component: AddlocationComponent },

      // transaction
      { path: 'transactions', component: VendorTransactionListComponent },
      { path: 'transactions/transaction/:email/:id', component: VendorTransactionDetailsComponent },

      // invoices
      { path: 'invoices', component: ListinvoicesComponent },
      { path: 'invoices/invoice/:id', component: AddinvoiceComponent },
      { path: 'payment/:id', component: PurchaseComponent },

      // Edit Account
      { path: 'account', component: EditAccountComponent },
    ]
  },
  //login
  { path: '', component: AccountloginComponentComponent },
  { path: 'login', component: AccountloginComponentComponent },
  { path: 'forget', component: ForgetpasswordComponent },
  { path: 'reset/:email/:randNo', component: ResetpasswordComponent },
  { path: 'validate/:id/:random', component: ValidatepasswordComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
