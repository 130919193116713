import { HttpClient } from '@angular/common/http';
import { getAllLifecycleHooks } from '@angular/compiler/src/lifecycle_reflector';
import { Injectable } from '@angular/core';
import * as jsonschema from 'jsonschema';
import { SubjectSubscriber } from 'rxjs/internal/Subject';

export interface AddEditData {
  section: object
  object: any;
  isEditMode: boolean;
  isUploadingData: boolean;
  isLoadingData: boolean;
  binaryFiles: Array<{ 'name': string, 'binaryData': string }>;

  //Reset form to initial state 
  resetForm()
  //To check if data exists
  hasData();
  //To load existing Data using GET
  loadData(id);
  //Function is binded whenever a file is slected using file input,this adds binary data to binary files array
  loadBinaryFile(event);
  //When form is submitted
  onSubmit();
  //Run this if binaryArray has files(optional)
  uploadBinaryData();
  //Run this if binaryArray has NO files
  uploadFormData()
}

export interface ListData {
  isLoadingData: boolean;
  objectList: any;
  pristineData: any;
  itemsPerPage: number;
  pageNumber: number
  object: any;

  //Loads all the Data using GET operation
  loadData();
  //To filter Data
  filterData(filterValue: string);
  //To Sort Data
  sortData(sortValue: string);
  //To Paginate Data
  changePage(number: number);
  //Helper fucntion
  counter(items: number)
}

@Injectable({
  providedIn: 'root'
})

export class UtilityService {

  vendorValidateURL = "https://dwr5mpoypj82j.cloudfront.net/validate";
  enterpriseValidateURL = "https://d5ln7flo5uu18.cloudfront.net/validate";
  subadminValidateURL = "https://dr5ac6u1o6vn9.cloudfront.net/validate";
  locationValidateURL = "https://db50drtz1e1ys.cloudfront.net/validate";
  passwordResetURL = "https://d5ln7flo5uu18.cloudfront.net/reset";
  etrValidateURL = "https://d1eqbgsf09hz76.cloudfront.net/etrvalidate";

  Validator = jsonschema.Validator;
  dovValidateSchema = new this.Validator();
  constructor(private http: HttpClient) { }

  uploadBinaryData(objectName, binaryData, s3BucketName) {
    var that = this;
    return new Promise(function (resolve, reject) {
      that.getPreSignedUrl(objectName, s3BucketName).then((url) => {
        that.saveDataS3(binaryData, url).then(() => {
          resolve({ 'name': objectName, 'url': url });
        }).catch((e) => {
          reject("Failed to Upload");
        });
      }).catch((e) => {
        reject("Failed to Upload");
      });
    });
  }

  async getPreSignedUrl(objectName, s3BucketName) {
    let data = { "name": objectName, 'type': 'put', "storage": s3BucketName }
    let Response = await this.http.post("https://hg6x56ixcl.execute-api.us-west-2.amazonaws.com/default/createPresignedUrl", data).toPromise();
    return Response['url'];
  }

  async saveDataS3(binaryData: any, url: any) {
    if (await this.http.put(url, binaryData).toPromise()) return true;
  }

  ATPProducts = [{ name: 'Dental Live Mail', value: 'Mail' }, { name: 'Dental Live Meet', value: 'Meet' }, { name: 'Dental Live Planner', value: 'Planner' }]

  apiData = {

    // admin module
    "accounts": {
      "object": {
        "dentalId": "",
        "accountfirstName": null,
        "accountlastName": null,
        "emailAddress": null,
        "dob": 0,
        "address": "",
        "userValid": false,
        "status": true,
        "dateCreated": 0,
        "dateUpdated": 0,
        "clinicName": null,
        "imageSrc": null,
        "phoneNumber": null,
        "dependencies": {
          "subusers": [],
          "purchases": []
        }
      },
      "schema": {
        "$schema": "http://json-schema.org/draft-04/schema#",
        "type": "object",
        "properties": {
          "dentalId": {
            "type": "string"
          },
          "accountfirstName": {
            "type": "string"
          },
          "accountlastName": {
            "type": "string"
          },
          "emailAddress": {
            "type": "string"
          },
          "dob": {
            "type": "any"
          },
          "address": {
            "type": "string"
          },
          "userValid": {
            "type": "boolean"
          },
          "status": {
            "type": "boolean"
          },
          "dateCreated": {
            "type": "integer"
          },
          "dateUpdated": {
            "type": "integer"
          },
          "clinicName": {
            "type": "string"
          },
          "imageSrc": {
            "type": "any"
          },
          "phoneNumber": {
            "type": "string"
          },
          "dependencies": {
            "type": "object",
            "properties": {
              "subusers": {
                "type": "array",
              },
              "purchases": {
                "type": "array",
              }
            },
          }
        },
        "required": [
          "accountfirstName",
          "accountlastName",
          "emailAddress",
          "userValid"
        ]
      },
      "ApiUrl": "https://l20bpfo7ob.execute-api.us-west-2.amazonaws.com/default/accounts",
      "bucket": 'dentallive-accounts',
      "bucketUrl": "https://dentallive-accounts.s3.us-west-2.amazonaws.com/",
      "subBucket": 'dentallive-subusers',
      "subBucketUrl": "https://dentallive-subusers.s3.us-west-2.amazonaws.com/",
    },
    "products": {
      "object": {
        "productID": "",
        "productName": "",
        "description": "",
        "dateCreated": 0,
        "dateUpdated": 0,
        "status": true,
        "features": [],
        "dependecies": {
          "packages": []
        }
      },
      "schema": {
        "$schema": "http://json-schema.org/draft-04/schema#",
        "type": "object",
        "properties": {
          "productID": {
            "type": "string"
          },
          "productName": {
            "type": "string"
          },
          "description": {
            "type": "string"
          },
          "dateCreated": {
            "type": "integer"
          },
          "dateUpdated": {
            "type": "integer"
          },
          "status": {
            "type": "boolean"
          },
          "features": {
            "type": "array",
            "items": [{
              "type": "object",
              "properties": {
                "featureName": {
                  "type": "string"
                },
                "dateUpdated": {
                  "type": "integer"
                },
                "description": {
                  "type": "string"
                }
              },
              "required": [
                "featureName",
                "dateUpdated"
              ]
            }]
          },
          "dependencies": {
            "type": "object",
            "properties": {
              "packages": {
                "type": "array",
                "items": [{
                  "type": "object",
                  "properties": {
                    "packageID": {
                      "type": "string"
                    },
                    "packageName": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "packageID",
                    "packageName"
                  ]
                }]
              }
            },
          }
        },
        "required": [
          "productName",
          "status",
          "features"
        ]
      },
      "ApiUrl": "https://ifsmnqktk4.execute-api.us-west-2.amazonaws.com/default/products",
      "bucket": 'dentallive-vendors'
    },
    "packages": {
      "object": {
        "packageID": "",
        "packageName": null,
        "description": null,
        "packagePrice": null,
        "products": [],
        "status": true,
        "dateCreated": 0,
        "dateUpdated": 0,
        "expirationDate": null,
        "activationDate": null,
        "deactivationDate": null,
        "tenure": null,
        "usersAllocated": null,
        "storageAllocated": null,
        "addOnList": [],
        "dependencies": {
          "vendors": [],
          "purchases": []
        }
      },
      "schema": {
        "$schema": "http://json-schema.org/draft-04/schema#",
        "type": "object",
        "properties": {
          "packageID": {
            "type": "string"
          },
          "packageName": {
            "type": "string"
          },
          "description": {
            "type": "string"
          },
          "packagePrice": {
            "type": "float"
          },
          "products": {
            "type": "array",
            "items": {}
          },
          "status": {
            "type": "boolean"
          },
          "dateCreated": {
            "type": "any"
          },
          "dateUpdated": {
            "type": "any"
          },
          "expirationDate": {
            "type": "any"
          },
          "activationDate": {
            "type": "any"
          },
          "deactivationDate": {
            "type": "any"
          },
          "tenure": {
            "type": "integer"
          },
          "usersAllocated": {
            "type": "integer"
          },
          "storageAllocated": {
            "type": "float"
          },
          "addOnList": {
            "type": "array",
            "items": [
              {
                "type": "object",
                "properties": {
                  "addonID": {
                    "type": "string"
                  },
                  "addonName": {
                    "type": "string"
                  },
                  "dateUpdated": {
                    "type": "any"
                  },
                  "description": {
                    "type": "string"
                  },
                  "parameterId": {
                    "type": "string"
                  },
                  "parameterValue": {
                    "type": "integer"
                  },
                  "price": {
                    "type": "float"
                  }
                },
                "required": [
                  "addonName",
                  "parameterId",
                  "parameterValue",
                  "price"
                ]
              }
            ]
          },
          "dependencies": {
            "type": "object",
            "properties": {
              "vendors": {
                "type": "array",
                "items": [
                  {
                    "type": "object",
                    "properties": {
                      "vendorID": {
                        "type": "string"
                      },
                      "firstName": {
                        "type": "string"
                      },
                      "lastName": {
                        "type": "string"
                      }
                    },
                    "required": [
                      "vendorID"
                    ]
                  }
                ]
              },
              "purchases": {
                "type": "array",
                "items": {}
              }
            }
          }
        },
        "required": [
          "packageName",
          "packagePrice",
          "products",
          "status",
          "activationDate",
          "tenure",
          "usersAllocated",
          "storageAllocated"
        ]
      },
      "ApiUrl": "https://oms5sh4336.execute-api.us-west-2.amazonaws.com/default/packages",
    },
    "vendors": {
      "object": {
        "url": this.vendorValidateURL,
        "vendorID": "",
        "firstName": null,
        "lastName": null,
        "phoneNumber": null,
        "email": null,
        "imageSrc": "",
        "maxCommission": null,
        "companyName": null,
        "address": null,
        "assignedPackages": [],
        "dateCreated": 0,
        "dateUpdated": 0,
        "status": true,
        "dependencies": {
          "purchases": []
        }
      },
      "schema": {
        "$schema": "http://json-schema.org/draft-04/schema#",
        "type": "object",
        "properties": {
          "url": {
            "type": "string"
          },
          "vendorID": {
            "type": "string"
          },
          "firstName": {
            "type": "string"
          },
          "lastName": {
            "type": "string"
          },
          "phoneNumber": {
            "type": "integer"
          },
          "email": {
            "type": "string"
          },
          "imageSrc": {
            "type": "string"
          },
          "maxCommission": {
            "type": "integer"
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "type": "string"
          },
          "assignedPackages": {
            "type": "array",
            "items": {}
          },
          "dateCreated": {
            "type": "integer"
          },
          "dateUpdated": {
            "type": "integer"
          },
          "password": {
            "type": "string"
          },
          "status": {
            "type": "boolean"
          },
          "dependencies": {
            "type": "object",
            "properties": {
              "purchases": {
                "type": "array",
                "items": {}
              }
            }
          }
        },
        "required": [
          "firstName",
          "lastName",
          "email",
          "maxCommission",
          "companyName",
          "status"
        ]
      },
      "ApiUrl": "https://m6bmrfyko3.execute-api.us-west-2.amazonaws.com/default/vendors",
      "bucket": 'dentallive-vendors',
      "bucketUrl": "https://dentallive-vendors.s3.us-west-2.amazonaws.com/"
    },
    "enterprise": {
      "object": {
        "url": this.enterpriseValidateURL,
        "etrID": "",
        "etrName": null,
        "contactName": null,
        "phoneNumber": null,
        "email": null,
        "alterEmail": null,
        "imageSrc": null,
        "address": null,

        "assignedProducts": [],
        "minUsers": 0,
        "pricePerUser": 0,
        "storagePerUser": 0,
        "interval": 0,
        "accountActive": true,
        "activationDate": null,

        "dateCreated": 0,
        "dateUpdated": 0,
        "status": true,
        "isValid": false
      },
      "schema": {
        "$schema": "http://json-schema.org/draft-04/schema#",
        "type": "object",
        "properties": {
          "url": {
            "type": "string"
          },
          "etrID": {
            "type": "string"
          },
          "etrName": {
            "type": "string"
          },
          "contactName": {
            "type": "string"
          },
          "phoneNumber": {
            "type": "string"
          },
          "storagePerUser": {
            "type": "float"
          },
          "email": {
            "type": "string"
          },
          "alterEmail": {
            "type": "string"
          },
          "imageSrc": {
            "type": "any"
          },
          "assignedProducts": {
            "type": "array"
          },
          "minUsers": {
            "type": "integer"
          },
          "pricePerUser": {
            "type": "float"
          },
          "interval": {
            "type": "integer"
          },
          "accountActive": {
            "type": "boolean"
          },
          "dateCreated": {
            "type": "integer"
          },
          "address": {
            "type": "string"
          },
          "activationDate": {
            "type": "integer"
          },
          "dateUpdated": {
            "type": "integer"
          },
          "status": {
            "type": "boolean"
          },
          "isValid": {
            "type": "boolean"
          }
        },
        "required": [
          "etrName",
          "contactName",
          "email",
          "alterEmail",
          "minUsers",
          "pricePerUser",
          "storagePerUser",
          "interval",
          "address",
          "activationDate",
          "dateCreated",
          "dateUpdated",
          "status",
          "isValid"
        ]
      },
      "ApiUrl": "https://ly29km1z2g.execute-api.us-west-2.amazonaws.com/default/enterprise",
      "bucket": 'dentallive-enterprise',
      "bucketUrl": "https://dentallive-enterprise.s3.us-west-2.amazonaws.com/"
    },
    "adminRoles": {
      "object": {
        "roleName": null,
        "permissionList": [],
        "roleID": "",
        "status": true,
        "dateCreated": 0,
        "dateUpdated": 0,
        "dependencies": {
          "subadmins": []
        }
      },
      "schema": {
        "$schema": "http://json-schema.org/draft-04/schema#",
        "type": "object",
        "properties": {
          "roleName": {
            "type": "string"
          },
          "permissionList": {
            "type": "array"
          },
          "roleID": {
            "type": "string"
          },
          "status": {
            "type": "boolean"
          },
          "dateCreated": {
            "type": "integer"
          },
          "dateUpdated": {
            "type": "integer"
          },
          "dependencies": {
            "type": "object",
            "properties": {
              "subadmins": {
                "type": "array",
                "items": [
                  {
                    "type": "object",
                    "properties": {
                      "subAdminID": {
                        "type": "string"
                      },
                      "firstName": {
                        "type": "string"
                      },
                      "lastName": {
                        "type": "string"
                      }
                    },
                    "required": [
                      "subAdminID"
                    ]
                  }
                ]
              }
            }
          }
        },
        "required": [
          "roleName",
          "permissionList",
          "status"
        ]
      },
      "ApiUrl": "https://d8tb964zy4.execute-api.us-west-2.amazonaws.com/default/adminrole",
    },
    "subAdmin": {
      "object": {
        "url": this.subadminValidateURL,
        "subAdminID": "",
        "firstName": null,
        "lastName": null,
        "email": null,
        "imageSrc": "",
        "roleID": "",
        "dateCreated": 0,
        "dateUpdated": 0,
        "status": true,
        "isValid": false
      },
      "schema": {
        "$schema": "http://json-schema.org/draft-04/schema#",
        "type": "object",
        "properties": {
          "url": {
            "type": "string"
          },
          "subAdminID": {
            "type": "string"
          },
          "firstName": {
            "type": "string"
          },
          "lastName": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "photo": {
            "type": "string"
          },
          "roleID": {
            "type": "string"
          },
          "dateCreated": {
            "type": "integer"
          },
          "dateUpdated": {
            "type": "integer"
          },
          "status": {
            "type": "boolean"
          },
          "password": {
            "type": "string"
          },
          "isValid": {
            "type": "boolean"
          }
        },
        "required": [
          "firstName",
          "lastName",
          "email",
          "roleID",
          "status",
          "isValid"
        ]
      },
      "ApiUrl": "https://2iqwslm56c.execute-api.us-west-2.amazonaws.com/default/subadmin",
      "bucket": 'dentallive-subadmin',
      "bucketUrl": "https://dentallive-subadmin.s3.us-west-2.amazonaws.com/"
    },
    "subAdminAccounts": {
      "object": {
        "subAdminID": "",
        "firstName": null,
        "lastName": null,
        "email": null,
        "imageSrc": "",
        "roleID": "",
        "dateCreated": 0,
        "dateUpdated": 0,
        "status": true,
        "isValid": false,
        "permission": {}
      },
      "schema": {
        "$schema": "http://json-schema.org/draft-04/schema#",
        "type": "object",
        "properties": {
          "url": {
            "type": "string"
          },
          "subAdminID": {
            "type": "string"
          },
          "firstName": {
            "type": "string"
          },
          "lastName": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "imageSrc": {
            "type": "string"
          },
          "roleID": {
            "type": "string"
          },
          "dateCreated": {
            "type": "integer"
          },
          "dateUpdated": {
            "type": "integer"
          },
          "status": {
            "type": "boolean"
          },
          "password": {
            "type": "string"
          },
          "isValid": {
            "type": "boolean"
          },
          "permission": {
            "type": "array"
          }
        },
        "required": [
          "firstName",
          "lastName",
          "email"
        ]
      },
      "ApiUrl": "https://0ofb8xakij.execute-api.us-west-2.amazonaws.com/default/subadminaccounts",
      "bucket": 'dentallive-subadmin',
      "bucketUrl": "https://dentallive-subadmin.s3.us-west-2.amazonaws.com/"
    },
    "cms": {
      "object": {
        "landingPage": {},
        "faqPage": {},
        "productPage": [],
        "dateUpdated": 0
      },
      "schema": {
        "$schema": "http://json-schema.org/draft-04/schema#",
        "type": "object",
        "properties": {
          "landingPage": {
            "type": "object"
          },
          "faqPage": {
            "type": "object"
          },
          "productPage": {
            "type": "array",
            "items": {}
          },
          "dateUpdated": {
            "type": "integer"
          }
        },
        "required": []
      },
      "ApiUrl": "https://nvkkg66ei2.execute-api.us-west-2.amazonaws.com/default/cms",
      "bucket": 'dentallive-cms',
      "bucketUrl": "https://dentallive-cms.s3.us-west-2.amazonaws.com/"
    },

    //vendor module
    "coupon": {
      "object": {
        "couponName": null,
        "discount": 0,
        "validFrom": Date.now(),
        "validTo": Date.now(),
        "selectPackages": [],
        "status": true,
        "dateCreated": 0,
        "dateUpdted": 0,
        "couponID": "",
        "vendorID": null,
        "freezed": false,
        "dependencies": {
          "purchases": []
        }
      },
      "schema": {
        "$schema": "http://json-schema.org/draft-04/schema#",
        "type": "object",
        "properties": {
          "couponName": {
            "type": "string"
          },
          "discount": {
            "type": "integer"
          },
          "validFrom": {
            "type": "any"
          },
          "validTo": {
            "type": "any"
          },
          "selectPackages": {
            "type": "array",
            "items": {}
          },
          "status": {
            "type": "boolean"
          },
          "dateCreated": {
            "type": "integer"
          },
          "dateUpdted": {
            "type": "integer"
          },
          "couponID": {
            "type": "string"
          },
          "vendorID": {
            "type": "string"
          },
          "freezed": {
            "type": "boolean"
          },
          "dependencies": {
            "type": "object",
            "properties": {
              "purchases": {
                "type": "array",
                "items": {}
              }
            }
          }
        },
        "required": [
          "couponName",
          "discount",
          "validFrom",
          "validTo",
          "vendorID",
          "selectPackages",
          "status",
          "freezed"
        ]
      },
      "ApiUrl": "https://gwrbq7el4e.execute-api.us-west-2.amazonaws.com/default/coupons",
      "bucketURL": "",
      "bucket": ""
    },
    "vendorClaims": {
      "object": {
        "claimID": "",
        "customerInvoiceId": null,
        "vendorID": null,
        "dateCreated": 0,
        "dateUpdated": 0,
        "status": false,

        "price": 0,
        "taxDeductions": 0,
        "totalPrice": 0,
        "currency": null,
      },
      "schema": {
        "$schema": "http://json-schema.org/draft-04/schema#",
        "type": "object",
        "properties": {
          "claimID": {
            "type": "string"
          },
          "customerInvoiceId": {
            "type": "string"
          },
          "vendorID": {
            "type": "string"
          },
          "dateCreated": {
            "type": "integer"
          },
          "dateUpdated": {
            "type": "integer"
          },
          "status": {
            "type": "boolean"
          },


          "price": {
            "type": "float"
          },
          "taxDeductions": {
            "type": "float"
          },
          "totalPrice": {
            "type": "float"
          },
          "currency": {
            "type": "string"
          }
        },
        "required": [
          "customerInvoiceId",
          "vendorID",
          "status"
        ]
      },
      "ApiUrl": "https://2rcb6zf2n3.execute-api.us-west-2.amazonaws.com/default/vendorclaims",
    },
    "vendorAccounts": {
      "object": {
        "vendorID": "",
        "firstName": "",
        "lastName": "",
        "phoneNumber": 0,
        "email": "",
        "picture": "",
        "companyName": "",
        "address": "",
        "dependencies": {
          "purchases": []
        }
      },
      "schema": {
        "$schema": "http://json-schema.org/draft-04/schema#",
        "type": "object",
        "properties": {
          "vendorID": {
            "type": "string"
          },
          "firstName": {
            "type": "string"
          },
          "lastName": {
            "type": "string"
          },
          "phoneNumber": {
            "type": "any"
          },
          "email": {
            "type": "string"
          },
          "imageSrc": {
            "type": "string"
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "type": "string"
          },
          "maxCommission": {
            "type": "integer"
          },
          "dependencies": {
            "type": "object",
            "properties": {
              "purchases": {
                "type": "array",
                "items": {}
              }
            }
          }
        },
        "required": [
          "vendorID",
          "firstName",
          "lastName",
          "email",
          "companyName",
        ]
      },
      "ApiUrl": "https://ew5os4prcd.execute-api.us-west-2.amazonaws.com/default/vendoraccounts",
      "bucket": 'dentallive-vendors',
      "bucketUrl": "https://dentallive-vendors.s3.us-west-2.amazonaws.com/"
    },

    //enterprise owner
    "location": {
      "object": {
        "url": this.locationValidateURL,
        "etrLocID": "",
        "etrID": null,
        "locationName": null,
        "ownerName": null,
        "phoneNumber": null,
        "email": null,
        "imageSrc": null,
        "address": null,

        "maxUsers": 0,

        "dateCreated": 0,
        "dateUpdated": 0,
        "status": true,
        "isValid": false
      },
      "schema": {
        "$schema": "http://json-schema.org/draft-04/schema#",
        "type": "object",
        "properties": {
          "url": {
            "type": "string"
          },
          "etrLocID": {
            "type": "string"
          },
          "etrID": {
            "type": "string"
          },
          "locationName": {
            "type": "string"
          },
          "ownerName": {
            "type": "string"
          },
          "phoneNumber": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "imageSrc": {
            "type": "any"
          },
          "address": {
            "type": "string"
          },
          "maxUsers": {
            "type": "integer"
          },
          "dateCreated": {
            "type": "integer"
          },
          "dateUpdated": {
            "type": "integer"
          },
          "status": {
            "type": "boolean"
          },
          "isValid": {
            "type": "boolean"
          }
        },
        "required": [
          "etrID",
          "locationName",
          "ownerName",
          "email",
          "maxUsers",
          "dateCreated",
          "dateUpdated",
          "status",
          "isValid"
        ]
      },
      "ApiUrl": "https://qyifohxrph.execute-api.us-west-2.amazonaws.com/default/location",
      "bucket": 'dentallive-location',
      "bucketUrl": "https://dentallive-location.s3.us-west-2.amazonaws.com/"
    },
    "ownerAccounts": {
      "object": {
        "etrID": null,
        "etrName": null,
        "contactName": null,
        "phoneNumber": null,
        "email": null,
        "alterEmail": null,
        "imageSrc": null,
        "address": null,
      },
      "schema": {
        "$schema": "http://json-schema.org/draft-04/schema#",
        "type": "object",
        "properties": {
          "url": {
            "type": "string"
          },
          "etrID": {
            "type": "string"
          },
          "etrName": {
            "type": "string"
          },
          "contactName": {
            "type": "string"
          },
          "phoneNumber": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "alterEmail": {
            "type": "string"
          },
          "imageSrc": {
            "type": "any"
          },
        },
        "required": [
          "etrID",
          "etrName",
          "contactName",
          "email",
          "alterEmail",
        ]
      },
      "ApiUrl": "https://54pfbuomd1.execute-api.us-west-2.amazonaws.com/default/owneraccounts",
      "bucket": 'dentallive-enterprise',
      "bucketUrl": "https://dentallive-enterprise.s3.us-west-2.amazonaws.com/"
    },
    "invoice": {
      "object": {},
      "schema": {},
      "ApiUrl": "https://9nbtwlm0e0.execute-api.us-west-2.amazonaws.com/default/enterpriseInvoice"
    },

    //enterprise location
    "locationAccounts": {
      "object": {
        "etrLocID": null,
        "etrID": null,
        "locationName": null,
        "ownerName": null,
        "phoneNumber": null,
        "email": null,
        "imageSrc": null,
        "address": null,
      },
      "schema": {
        "$schema": "http://json-schema.org/draft-04/schema#",
        "type": "object",
        "properties": {
          "etrLocID": {
            "type": "string"
          },
          "etrID": {
            "type": "string"
          },
          "locationName": {
            "type": "string"
          },
          "ownerName": {
            "type": "string"
          },
          "phoneNumber": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "imageSrc": {
            "type": "any"
          },
          "address": {
            "type": "string"
          }
        },
        "required": [
          "etrLocID",
          "etrID",
          "locationName",
          "ownerName",
          "email"
        ]
      },
      "ApiUrl": "https://uib9nuin7l.execute-api.us-west-2.amazonaws.com/default/locationAccounts",
      "bucket": 'dentallive-location',
      "bucketUrl": "https://dentallive-location.s3.us-west-2.amazonaws.com/"
    },
    "etrUsers": {
      "object": {
        "url": this.etrValidateURL,
        "accountID": "",
        "accountfirstName": null,
        "accountlastName": null,
        "emailAddress": null,
        "dob": 0,
        "address": null,
        "userValid": false,
        "status": true,
        "dateCreated": 0,
        "dateUpdated": 0,
        "imageSrc": null,
        "phoneNumber": null,
        "etrID": null,
        "etrLocID": null
      },
      "schema": {
        "$schema": "http://json-schema.org/draft-04/schema#",
        "type": "object",
        "properties": {
          "url": {
            "type": "string"
          },
          "accountID": {
            "type": "string"
          },
          "accountfirstName": {
            "type": "string"
          },
          "accountlastName": {
            "type": "string"
          },
          "emailAddress": {
            "type": "string"
          },
          "dob": {
            "type": "any"
          },
          "address": {
            "type": "string"
          },
          "userValid": {
            "type": "boolean"
          },
          "status": {
            "type": "boolean"
          },
          "dateCreated": {
            "type": "integer"
          },
          "dateUpdated": {
            "type": "integer"
          },
          "imageSrc": {
            "type": "any"
          },
          "phoneNumber": {
            "type": "string"
          },
          "etrID": {
            "type": "string"
          },
          "etrLocID": {
            "type": "string"
          }
        },
        "required": [
          "accountfirstName",
          "accountlastName",
          "emailAddress",
          "userValid",
          "etrID",
          "etrLocID"
        ]
      },
      "ApiUrl": "https://lfeoipshwe.execute-api.us-west-2.amazonaws.com/default/etrUsers",
      "bucket": 'dentallive-accounts',
      "bucketUrl": "https://dentallive-accounts.s3.us-west-2.amazonaws.com/"
    },



    //user module
    "userAccounts": {
      "object": {
        "dentalId": "",
        "firstName": "",
        "lastName": "",
        "email": "",
        "dob": 0,
        "address": "",
        "userValid": false,
        "status": true,
        "dateCreated": 0,
        "dateUpdated": 0,
        "clinicName": "",
        "imageSrc": "",
        "phoneNumber": "",
        "password": ""
      },
      "schema": {
        "$schema": "http://json-schema.org/draft-04/schema#",
        "type": "object",
        "properties": {
          "dentalId": {
            "type": "string"
          },
          "firstName": {
            "type": "string"
          },
          "lastName": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "dob": {
            "type": "integer"
          },
          "address": {
            "type": "string"
          },
          "userValid": {
            "type": "boolean"
          },
          "status": {
            "type": "boolean"
          },
          "dateCreated": {
            "type": "integer"
          },
          "dateUpdated": {
            "type": "integer"
          },
          "clinicName": {
            "type": "string"
          },
          "imageSrc": {
            "type": "string"
          },
          "phoneNumber": {
            "type": "string"
          },
          "password": {
            "type": "string"
          }
        },
        "required": [
          "firstName",
          "lastName",
          "email",
          "userValid",
          "status"
        ]
      },
      "ApiUrl": "https://4knzuow8v7.execute-api.us-west-2.amazonaws.com/default/useraccounts",
    },
    "userRoles": {
      "object": {
        "roleName": "",
        "permission": [],
        "roleID": "",
        "packageID": "",
        "dateCreated": 0,
        "dateUpdated": 0,
        "accountMail": "",
        "dependencies": {
          "users": []
        }
      },
      "schema": {
        "$schema": "http://json-schema.org/draft-04/schema#",
        "type": "object",
        "properties": {
          "roleName": {
            "type": "string"
          },
          "permission": {
            "type": "array",
            "items": {}
          },
          "roleID": {
            "type": "string"
          },
          "packageID": {
            "type": "string"
          },
          "dateCreated": {
            "type": "integer"
          },
          "dateUpdated": {
            "type": "integer"
          },
          "accountMail": {
            "type": "string"
          },
          "dependencies": {
            "type": "object",
            "properties": {
              "users": {
                "type": "array",
                "items": [
                  {
                    "type": "object",
                    "properties": {
                      "subUserID": {
                        "type": "string"
                      },
                      "firstName": {
                        "type": "string"
                      },
                      "lastName": {
                        "type": "string"
                      }
                    },
                    "required": [
                      "subUserID"
                    ]
                  }
                ]
              }
            }
          }
        },
        "required": [
          "roleName",
          "permission",
          "packageID",
          "accountMail"
        ]
      },
      "ApiUrl": "https://f1b09pqdo2.execute-api.us-west-2.amazonaws.com/default/userrole",
    },
    "subUser": {
      "object": {
        "subUserId": "",
        "firstName": "",
        "lastName": "",
        "email": "",
        "photo": "",
        "roleID": "",
        "dateCreated": "",
        "dateUpdated": "",
        "status": true,
        "password": "",
        "isValid": false,
        "accountMail": "",
        "packageId": ""
      },
      "schema": {
        "$schema": "http://json-schema.org/draft-04/schema#",
        "type": "object",
        "properties": {
          "subUserId": {
            "type": "string"
          },
          "firstName": {
            "type": "string"
          },
          "lastName": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "photo": {
            "type": "string"
          },
          "roleID": {
            "type": "string"
          },
          "dateCreated": {
            "type": "integer"
          },
          "dateUpdated": {
            "type": "integer"
          },
          "status": {
            "type": "boolean"
          },
          "password": {
            "type": "string"
          },
          "isValid": {
            "type": "boolean"
          },
          "accountMail": {
            "type": "string"
          },
          "packageId": {
            "type": "string"
          }
        },
        "required": [
          "firstName",
          "lastName",
          "email",
          "roleID",
          "status",
          "isValid",
          "accountMail",
          "packageId"
        ]
      },
      "ApiUrl": "https://ewrv2gh20a.execute-api.us-west-2.amazonaws.com/default/subuser",
    },
    "subUserAccounts": {
      "object": {
        "subUserId": "",
        "firstName": "",
        "lastName": "",
        "email": "",
        "photo": "",
        "roleID": "",
        "dateCreated": "",
        "dateUpdated": "",
        "status": true,
        "password": "",
        "isValid": false,
        "accountMail": "",
        "packageId": ""
      },
      "schema": {
        "$schema": "http://json-schema.org/draft-04/schema#",
        "type": "object",
        "properties": {
          "subUserId": {
            "type": "string"
          },
          "firstName": {
            "type": "string"
          },
          "lastName": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "photo": {
            "type": "string"
          },
          "roleID": {
            "type": "string"
          },
          "dateCreated": {
            "type": "integer"
          },
          "dateUpdated": {
            "type": "integer"
          },
          "status": {
            "type": "boolean"
          },
          "password": {
            "type": "string"
          },
          "isValid": {
            "type": "boolean"
          },
          "accountMail": {
            "type": "string"
          },
          "packageId": {
            "type": "string"
          }
        },
        "required": [
          "firstName",
          "lastName",
          "email",
          "roleID",
          "status",
          "accountMail",
          "packageId"
        ]
      },
      "ApiUrl": "https://4mvqhultg3.execute-api.us-west-2.amazonaws.com/default/subuseraccounts",
    },
    "purchases": {
      "object": {
        "payPal": {},
        "customerInvoiceId": "",
        "email": "",
        "accountID": "",
        "dateCreated": 0,
        "dateUpdated": 0,
        "packageID": "",
        "modeOfPayment": "",
        "isAddOn": false,
        "addOnId": "",
        "price": 0,
        "taxPrice": 0,
        "totalPrice": 0,
        "currency": "",
        "couponCode": "",
        "couponID": "",
        "isRenewal": false,
        "renewalDate": 0,
        "vendorID": "",
        "dependencies": {
          "package": [],
          "customer": [],
          "claims": [],
          "vendor": []
        }
      },
      "schema": {
        "$schema": "http://json-schema.org/draft-04/schema#",
        "type": "object",
        "properties": {
          "payPal": {
            "type": "object"
          },
          "customerInvoiceId": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "accountID": {
            "type": "string"
          },
          "dateCreated": {
            "type": "integer"
          },
          "dateUpdated": {
            "type": "integer"
          },
          "packageID": {
            "type": "string"
          },
          "modeOfPayment": {
            "type": "string"
          },
          "isAddOn": {
            "type": "boolean"
          },
          "addOnId": {
            "type": "string"
          },
          "price": {
            "type": "float"
          },
          "taxPrice": {
            "type": "float"
          },
          "totalPrice": {
            "type": "float"
          },
          "currency": {
            "type": "string"
          },
          "couponCode": {
            "type": "string"
          },
          "couponID": {
            "type": "string"
          },
          "isRenewal": {
            "type": "boolean"
          },
          "renewalDate": {
            "type": "integer"
          },
          "vendorID": {
            "type": "string"
          },
          "dependencies": {
            "type": "object",
            "properties": {
              "claims": {
                "type": "any",
              },
              "vendor": {
                "type": "object",
              },
              "package": {
                "type": "object"
              },
              "customer": {
                "type": "object"
              }
            }
          }
        },
        "required": [
          "payPal",
          "email",
          "accountID",
          "packageID",
          "modeOfPayment",
          "isAddOn",
          "currency",
          "couponCode",
          "couponID",
          "isRenewal"
        ]
      },
      "ApiUrl": "https://fsiyqdwc8j.execute-api.us-west-2.amazonaws.com/default/purchases",
      "bucket": 'dentallive-accounts',
      "bucketUrl": "https://dentallive-accounts.s3.us-west-2.amazonaws.com/"
    },
    "userPurchases": {
      "object": {
        "payPal": {},
        "customerInvoiceId": "",
        "email": "",
        "accountID": "",
        "dateCreated": 0,
        "dateUpdated": 0,
        "packageID": "",
        "modeOfPayment": "Paypal",
        "isAddOn": false,
        "addOnId": "",
        "price": 0,
        "taxPrice": 0,
        "totalPrice": 0,
        "currency": "USD",
        "couponCode": "",
        "couponID": "",
        "isRenewal": false,
        "renewalDate": 0,
        "vendorID": "",
        "dependencies": {
          "claims": [],
          "vendor": []
        }
      },
      "schema": {
        "$schema": "http://json-schema.org/draft-04/schema#",
        "type": "object",
        "properties": {
          "payPal": {
            "type": "object"
          },
          "customerInvoiceId": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "accountID": {
            "type": "string"
          },
          "dateCreated": {
            "type": "integer"
          },
          "dateUpdated": {
            "type": "integer"
          },
          "packageID": {
            "type": "string"
          },
          "modeOfPayment": {
            "type": "string"
          },
          "isAddOn": {
            "type": "boolean"
          },
          "addOnId": {
            "type": "string"
          },
          "price": {
            "type": "float"
          },
          "taxPrice": {
            "type": "float"
          },
          "totalPrice": {
            "type": "float"
          },
          "currency": {
            "type": "string"
          },
          "couponCode": {
            "type": "string"
          },
          "couponID": {
            "type": "string"
          },
          "isRenewal": {
            "type": "boolean"
          },
          "renewalDate": {
            "type": "integer"
          },
          "vendorID": {
            "type": "string"
          },
          "dependencies": {
            "type": "object",
            "properties": {
              "claims": {
                "type": "array",
                "items": [{
                  "type": "object",
                  "properties": {
                    "claimID": {
                      "type": "string"
                    },
                    "status": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "claimID",
                    "status"
                  ]
                }]
              },
              "vendor": {
                "type": "array",
                "items": [{
                  "type": "object",
                  "properties": {
                    "vendorID": {
                      "type": "string"
                    },
                    "firstName": {
                      "type": "string"
                    },
                    "lastName": {
                      "type": "string"
                    },
                    "picture": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "vendorID",
                    "firstName",
                    "lastName"
                  ]
                }]
              }
            }
          }
        },
        "required": [
          "payPal",
          "email",
          "accountID",
          "packageID",
          "modeOfPayment",
          "isAddOn",
          "currency",
          "couponCode",
          "couponID",
          "isRenewal"
        ]
      },
      "ApiUrl": "https://chvuidsbp0.execute-api.us-west-2.amazonaws.com/default/userpurchases",
    },
    "accountUsage": {
      "ApiUrl": "https://89vx1esx7k.execute-api.us-west-2.amazonaws.com/default/usage",
    }
  }
}
